<template>
    <div class="dashboard-body">
        <div class="row">
            <sidenav class="custom-col-width-2" />
            <div style="padding-left: 0px!important" class="custom-col-width-10 content-section dashboard-content">
                <navbar />
                <div class="content">
                    <br>
                    <div class="row button-grp justify-content-between">
                        <div class="col">
                            <h4>Reports</h4>
                        </div>
                        <!-- <div class="col">
                            <button v-on:click="selectOrganizationToEdit()" class="btn btn-add">Edit</button>
                        </div> -->
                    </div>
                    <div class="row justify-content-between">
                        <div class="">
                            <reportsNavbar class="" />
                        </div>
                        <div class="col">
                            <router-link :to="{ name: DONATION_REPORT_ROUTE }" class="btn btn-view-report">View
                                Reports</router-link>
                        </div>
                    </div>
                    <h6 v-if="donationReportStatsIsLoading && (donationReportStats == null)" class="text-center"><br>Loading... <br></h6>
                    <div v-if="donationReportStats">
                        <div class="row">
                        <!-- <div class="col-lg-9 col-md-12 col-sm-12">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="chart-title">Last 20 days dontations</h4>
                                    <span class="chart-sub-title">Keep up your good work!</span>
                                    <hr>
                                    <apexchart ref="usersChart" :height="isWideScreen ? '350' : '250'" type="line"
                                        class="" :options="chartOptions" :series="series"></apexchart>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-lg-3 col-md-12 col-sm-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="stats-content">
                                        <h6 class="title">All Time Donations</h6>
                                        <h3 class="value">{{
                                            this.$filters.formatPrice(donationReportStats.allTimeTotalDonations, 'USD')
                                            }}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 stats-col">
                            <div class="card">
                                <div class="card-body">
                                    <div class="stats-content">
                                        <h6 class="title">Donations This Year</h6>
                                        <h3 class="value"> {{
                                            this.$filters.formatPrice(donationReportStats.currYearTotalDonations,
                                            'USD') }}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="card">
                                <div class="card-body">
                                    <div class="stats-content">
                                        <h6 class="title">Donations This Month</h6>
                                        <h3 class="value"> {{
                                            this.$filters.formatPrice(donationReportStats.currMonthTotalDonations,
                                            'USD') }}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="card">
                                <div class="card-body">
                                    <div class="stats-content">
                                        <h6 class="title">Donations This Week</h6>
                                        <h3 class="value"> {{
                                            this.$filters.formatPrice(donationReportStats.currWeekTotalDonations,
                                            'USD') }}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-none d-lg-block col-lg-3 col-md-12 col-sm-12 stats-col">


                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8">
                            <div class="card" style="margin-top: 10px;">
                                <div class="card-body">
                                    <h5 class="chart-title">Monthly dontations</h5>
                                    <apexchart type="bar" height="350" :options="barChartOptions" :series="barSeries">
                                    </apexchart>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="card table-card" style="margin-top: 10px;">
                                <div class="card-body">
                                    <h5 class="chart-title">Donation Types</h5>
                                    <br>
                                    <div class="row">
                                        <div class="col-10 offset-1">
                                            <Doughnut height="70" :data="doughnutChartData" :options="doughnutChartOptions" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <div class="card table-card" style="margin-top: 10px;">
                                <div class="card-body">
                                    <h6 class="chart-title">Top Donnors</h6>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Donor</th>
                                                <th scope="col">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="donationReportStats.topDonors">
                                            <template v-bind:key="index"
                                                v-for="(member, index) in donationReportStats.topDonors">
                                                <tr>
                                                    <th scope="row">{{ index + 1 }}</th>
                                                    <td>{{ member.fname }} {{ member.lname }}</td>
                                                    <td>{{ $filters.formatPrice(member.amount, 'USD') }}</td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueApexCharts from 'vue3-apexcharts'

import { ContentLoader } from 'vue-content-loader';

import sidenav from '@/components/Sidenav.vue'
import navbar from '@/components/Navbar.vue'
import reportsNavbar from '@/components/Reports_Navbar.vue'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'vue-chartjs'

import { GET_DONATION_REPORT_STATS } from '@/store/action_types'
import { DONATION_REPORT_ROUTE } from '@/router/routes'

ChartJS.register(ArcElement, Tooltip, Legend)

export default {
    title: 'Details',
    components: {
        sidenav,
        navbar,
        reportsNavbar,
        Doughnut,
        ContentLoader,
        apexchart: VueApexCharts,

    },
    data() {
        return {
            DONATION_REPORT_ROUTE,
            isWideScreen: false,
            series: [{
                name: 'Donations',
                data: []
            },],
            chartOptions: {
                chart: {
                    height: 350,
                    type: 'line'
                },
                colors: ['#6A8CC6'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    width: 4
                },
                xaxis: {
                    categories: []
                },
                grid: {
                    borderColor: '#C2C6CE',
                    strokeDashArray: 4
                },
                tooltip: {
                    x: {
                        format: 'dd/MM/yy HH:mm'
                    },
                },
            },
            barSeries: [{
                name: "Donations",
                data: [
                    {
                        x: '2019/01/01',
                        y: 400
                    },
                    {
                        x: '2019/04/01',
                        y: 430
                    },
                    {
                        x: '2019/07/01',
                        y: 448
                    },
                    {
                        x: '2019/10/01',
                        y: 470
                    },
                    {
                        x: '2020/01/01',
                        y: 540
                    },
                    {
                        x: '2020/04/01',
                        y: 580
                    },
                    {
                        x: '2020/07/01',
                        y: 690
                    },
                    {
                        x: '2020/10/01',
                        y: 690
                    },
                    {
                        x: '2020/01/01',
                        y: 540
                    },
                    {
                        x: '2020/04/01',
                        y: 580
                    },
                    {
                        x: '2020/07/01',
                        y: 690
                    },
                    {
                        x: '2020/10/01',
                        y: 690
                    }
                ]
            }],
            barChartOptions: {
                chart: {
                    type: 'bar',
                    height: 380
                },
                colors: ['#6A8CC6'],
                xaxis: {
                    type: 'category',
                    labels: {
                        
                    },
                    group: {
                        style: {
                            fontSize: '10px',
                            fontWeight: 700
                        }
                    }
                },
                title: {
                    text: '',
                },
                // tooltip: {
                //     x: {
                //         formatter: function (val) {
                //             return "Q" + dayjs(val).quarter() + " " + dayjs(val).format("YYYY")
                //         }
                //     }
                // },
            },
            doughnutChartData: {
                datasets: [{
                    backgroundColor: ['#9FA6AB', '#A3C1D1', '#406C8E', '#1C3E5A'],
                    data: [0, 0, 0, 0],
                }]
            },
            doughnutChartOptions: {
                responsive: true,
                cutout: '75%', // Adjust this value for thickness
                // maintainAspectRatio: false
                // aspectRatio: 2
            },
        }
    },
    computed: {
        route() {
            return this.$route.name
        },
        donationReportStats() {
            return this.$store.getters.donationReportStats.data
        },
        donationReportStatsIsLoading() {
            return this.$store.getters.donationReportStats.isLoading
        },
        account() {
            return this.$store.getters.account.data
        },
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        checkScreenWidth() {
            this.isWideScreen = window.innerWidth > 1500;
        },
        updateSeriesLine() {
            // update line chart
            this.series = [{
                name: 'Donations',
                data: this.donationReportStats.last10DaysDonation.amount
            }]
            this.chartOptions = {
                chart: {
                    type: 'line'
                },
                colors: ['#6A8CC6'],
                stroke: {
                    curve: 'smooth',
                    width: 3
                },
                dataLabels: {
                    enabled: false
                },
                grid: {
                    borderColor: '#C2C6CE',
                    strokeDashArray: 4
                },
                xaxis: {
                    categories: this.donationReportStats.last10DaysDonation.weekDays
                },
            };
            // update bar chart
            this.barSeries = [{
                name: 'Donations',
                data: this.donationReportStats.donationsByMonth.map((e) => e.amount)
            }]
            this.barChartOptions = {
                chart: {
                    type: 'bar',
                    height: 380
                },
                colors: ['#6A8CC6'],
                xaxis: {
                    categories: this.donationReportStats.donationsByMonth.map((e) => e.month),
                },
                title: {
                    text: '',
                },
                tooltip: {
                    x: this.donationReportStats.donationsByMonth.map((e) => e.month)
                },
            }
        },
        updateDoughnutChart() {
            this.doughnutChartData = {
                labels: Object.keys(this.donationReportStats.donationCountByType),
                datasets: [{
                    backgroundColor: ['#9FA6AB', '#A3C1D1', '#406C8E', '#1C3E5A'],
                    data: Object.values(this.donationReportStats.donationCountByType),
                }]
            }
        },
        getDonationReportStats() {
            this.$store.dispatch(GET_DONATION_REPORT_STATS, { org: this.account.org }).then((res) => {
                if (res) {
                    this.updateSeriesLine()
                    this.updateDoughnutChart()
                }
            })
        }
    },
    mounted() {
        this.checkScreenWidth()
        if (this.donationReportStats != null) {
            this.updateSeriesLine()
        }
        this.getDonationReportStats()
    }
}
</script>
<style scoped>
.btn-view-report {
    font-weight: 600;
    color: #5D84C2;
    float: right;
    margin-top: 8px;
}

.content .donations .card,
.content .donations .card-body {
    padding: 0px;
}

.content .donations {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #F4ECE2;
}

.content .timer {
    font-size: 18px;
    font-weight: 400 !important;
    color: #606060;
    padding-bottom: 10px;
}

.content .welcome {
    font-size: 26px;
}

.content .timer span {
    margin-left: 7px;
    margin-right: 7px;
}

.content .org-dropdown {
    position: relative;
    top: 23px;
    float: right;
}

.content .org-dropdown .btn {
    border-radius: 8px;
    font-size: 14px;
    color: #6084C2;
    border-radius: 8px;
    border: 1px solid transparent;
    background-color: #D9E2F1;
}

.content .org-dropdown .dropdown-menu {
    border-radius: 8px;
    border: 1px solid #E0E2E7;
}

.content .org-dropdown .dropdown-item {
    font-size: 14px;
    cursor: pointer;
}

.content .org-dropdown .dropdown-item:hover {
    background-color: #F5F5FB;
}

.content .org-dropdown .dropdown-item:active {
    color: #212529;
    background-color: #edf4ff;
}

.stats-col {
    padding-left: 0px;
    padding-right: 0px;
}

.stats-card-1,
.stats-card-1 .card-body,
.stats-card-2,
.stats-card-2 .card-body {
    padding: 0px;
    background-color: transparent;
}

.stats-card-1 {
    background-image: url('../../../assets/images/dashboard-img-1.png');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    height: 180%;
}

.stats-card-2 {
    background-image: url('../../../assets/images/dashboard-img-2.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    height: 50%;
}

.stats-card-1 .card-body .stats-content .title,
.stats-card-2 .card-body .stats-content .title {
    color: #D7E0F0;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.6px;
    margin-bottom: 11px;
}

.stats-card-1 .card-body .stats-content .value,
.stats-card-2 .card-body .stats-content .value {
    color: #FFFFFF;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0.6px;
}

.card .card-body hr {
    border: 1px solid transparent;
}

.table th {
    padding: 23px 30px 23px 30px !important;
}

table thead {
    background-color: #F9F9F9 !important;
    border-bottom: 1px solid #F0F1F3 !important;
}

.table tr td {
    padding: 23px 30px 23px 30px !important;
    border-bottom: 1px solid #F0F1F3 !important;
}

.table-title {
    padding: 12px 30px 12px 15px;
}

.table-card .card-body {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 15px;
}

.table-card .chart-title {
    padding: 1.2rem 1.2rem 0.5rem 1.2rem;
}

.table-card .btn {
    float: right;
    font-size: 14px;
    font-weight: 600;
    color: #6084C2;
    border-radius: 8px;
    background-color: #D9E2F1;
    margin-right: 12px;
}

.table-card .badge-active {
    font-size: 14px !important;
}

.content .donations .card table .striped {
    background-color: #FAF7F4 !important;
}

.content .donations .card table tbody tr:hover {
    background-color: #FAF7F4 !important;
}

.content .card .card-body small {
    font-size: 17px;
}

.content .card table .pi-eye {
    font-size: 20px;
}

.content .card table .pi-eye {
    cursor: pointer;
}

.chart-sub-title {
    color: #777980;
}

.custom-col-width-2 {
    width: 16.66%;
}

.custom-col-width-10 {
    width: 83.3%;
}

.d-none,
.d-lg-block {
    border-top: 0px solid transparent !important;
    border-bottom: 0px solid transparent !important;
}

@media (max-width: 1000px) {
    .custom-col-width-2 {
        width: 12%;
    }

    .custom-col-width-10 {
        width: 88%;
    }
}

@media (max-width: 1500px) {
    
}

@media (min-width: 1500px) {
    .stats-card-1 {
        height: 49%;
        margin-bottom: 10px;
    }

    .stats-card-2 {
        height: 49%;
    }

    .stats-card-1 .card-body .stats-content {
        
    }

    .stats-card-2 .card-body .stats-content {
        
    }

    .stats-card-1 .card-body .stats-content .title,
    .stats-card-2 .card-body .stats-content .title {
        font-size: 18px;
    }

    .stats-card-1 .card-body .stats-content .value,
    .stats-card-2 .card-body .stats-content .value {
        font-size: 32px;
    }

    .stats-col {
        padding-left: 10px;
        padding-right: 10px;
    }
}

/* typically considered medium screens */
@media (max-width: 1199px) and (min-width: 768px) {
    .mid-screen-stats {
        margin-bottom: 0px;
    }

    .stats-card-1 {
        background-image: url('../../../assets/images/dashboard-img-1.png');
        background-repeat: no-repeat;
        background-position: top;
        background-size: contain;
        height: 250px;
    }

    .stats-card-2 {
        background-image: url('../../../assets/images/dashboard-img-2.png');
        background-repeat: no-repeat;
        background-position: top;
        background-size: contain;
        height: 250px;
    }

    .stats-card-1 .card-body .stats-content {
        position: absolute;
        bottom: 50px;
        left: 30px;
    }

    .stats-card-2 .card-body .stats-content {
        position: absolute;
        bottom: 50px;
        left: 30px;
    }

    .stats-card-1 .card-body .stats-content .title,
    .stats-card-2 .card-body .stats-content .title {
        font-size: 18px;
    }

    .stats-card-1 .card-body .stats-content .value,
    .stats-card-2 .card-body .stats-content .value {
        font-size: 32px;
    }
}
</style>