import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import multiguard from 'vue-router-multiguard';
import store from '@/store';
import { ACCOUNT_ROUTE, ALERT_ROUTE, ANNOUNCEMENTS_ROUTE, DASHBOARD_ROUTE, DISPLAY_ROUTE, DONATIONS_ROUTE, LOGIN_ROUTE, ORGANIZATION_ACCOUNTS_ROUTE, ORGANIZATION_ANNOUNCEMENTS_ROUTE, ORGANIZATION_DETAILS_ROUTE, ORGANIZATION_DONATIONS_ROUTE, ORGANIZATION_MEMBERS_ROUTE, ORGANIZATION_MINYANIM_ROUTE, ORGANIZATION_NOTIFICATIONS_ROUTE, ORGANIZATION_SCREENS_ROUTE, ORGANIZATION_SYNAGOGUES_ROUTE, ORGANIZATION_SHIURIMS_ROUTE, ORGANIZATION_TEMPLATES_ROUTE, ORGANIZATIONS_ROUTE, SCREENS_ROUTE, TEMPLATES_ROUTE, TICKETS_ROUTE, ORGANIZATION_INVOICES_ROUTE, MEMBER_INVOICES_ROUTE, MEMBERS_ROUTE, MEMBER_DETAILS_ROUTE, MEMBER_DONATIONS_ROUTE, MEMBER_SEATS_ROUTE, MEMBER_PURPOSES_ROUTE, MEMBER_INVOICE_PAYMENTS_ROUTE, MEMBER_RECEIPTS_ROUTE, MEMBER_PLEDGES_ROUTE, MEMBER_PLEDGE_ITEM_PAYMENT_LOGS_ROUTE, DONATION_REPORT_STATS_ROUTE, REPORT_SEATS_ROUTE, REPORT_PLEDGES_ROUTE, DONATION_REPORT_ROUTE, SYNAGOGUES_ROUTE, ORGANIZATION_SETTINGS_ROUTE, ORGANIZATION_ALIASES_ROUTE, ORGANIZATION_REPORTS_ROUTE } from './routes'
import { ifNotAuthDeny } from './guards/not_auth'
import { ifAuthDeny } from './guards/auth'
import { clearDisplayDonations, updateAuthAccount, updateSettings } from './router_methods'
import { CLEAR_ERRORMSG, GET_MODULE_SETTINGS, UPDATE_ROUTER_LOADER } from '@/store/action_types';
import Dashboard from '../views/Dashboard.vue'
import LoginView from '../views/auth/Login2.vue'
import DisplayView from '../views/display/Display.vue'
import DonationView from '../views/Donations.vue'
import SynagoguesView from '../views/Synagogues.vue'
import TemplatesView from '../views/Templates.vue'
import DisplayScreensView from '../views/Display_Screens.vue'
import AnnouncementsView from '../views/Announcements.vue'
import OrganizationsView from '../views/organization/Organizations.vue'
import OrganizationDetailsView from '../views/organization/Details.vue'
import OrganizationAccountsView from '../views/organization/Accounts.vue'
import OrganizationDonationsView from '../views/organization/Donations.vue'
import OrganizationMinyanimView from '../views/organization/Minyanim.vue'
import OrganizationAnnouncementsView from '../views/organization/Announcements.vue'
import OrganizationShiurimView from '../views/organization/Shiurim.vue'
import OrganizationScreensView from '../views/organization/Screens.vue'
import OrganizationMembersView from '../views/organization/Members.vue'
import OrganizationSynagoguesView from '../views/organization/Synagogues.vue'
import OrganizationTemplatesView from '../views/organization/Templates.vue'
import OrganizationAliasView from '../views/organization/Alias.vue'
import OrganizationInvoicesView from '../views/organization/Invoices.vue'
import OrganizationNotificationsView from '../views/organization/Notifications.vue'
import OrganizationSettingsView from '../views/organization/Settings.vue'
import OrganizationReportsView from '../views/organization/Settings.vue'
import MembersView from '../views/Member/Members.vue'
import MemberDetailsView from '../views/Member/Details.vue'
import MemberDonationsView from '../views/Member/Donations.vue'
import MemberPurposesView from '../views/Member/Purposes.vue'
import MemberSeatsView from '../views/Member/Seats.vue'
import MemberInvoicesView from '../views/Member/Invoices.vue'
import MemberInvoicePaymentsView from '../views/Member/Invoice_Payments.vue'
import MemberReceiptsView from '../views/Member/Receipts.vue'
import MemberPledgesView from '../views/Member/Pledges.vue'
import MemberPledgePaymentsView from '../views/Member/Pledge_Payments.vue'
import DonationReportStatsView from '../views/report/Donation/Donation_Stats.vue'
import DonationReportView from '../views/report/Donation/Donation_Report.vue'
import ReportSeatsView from '../views/report/Donation/Donation_Stats.vue'
import ReportPledgesView from '../views/report/Donation/Donation_Stats.vue'
import AccountsView from '../views/Users.vue'
import AlertsView from '../views/Alerts.vue'
import TicketsView from '../views/Tickets.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: DASHBOARD_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: Dashboard
  },
  {
    path: '/login',
    name: LOGIN_ROUTE,
    beforeEnter: ifAuthDeny,
    component: LoginView
  },
  {
    path: '/display/:id/:org',
    name: DISPLAY_ROUTE,
    // beforeEnter: multiguard([ifNotAuthDeny, clearDisplayDonations]),
    beforeEnter: multiguard([clearDisplayDonations]),
    component: DisplayView
  },
  {
    path: '/donations',
    name: DONATIONS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: DonationView
  },
  {
    path: '/seats',
    name: SYNAGOGUES_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: SynagoguesView
  },
  {
    path: '/templates',
    name: TEMPLATES_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: TemplatesView
  },
  {
    path: '/screens',
    name: SCREENS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: DisplayScreensView
  },
  {
    path: '/announcements',
    name: ANNOUNCEMENTS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: AnnouncementsView
  },
  {
    path: '/organizations',
    name: ORGANIZATIONS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: OrganizationsView
  },
  {
    path: '/organizations/:id/details',
    name: ORGANIZATION_DETAILS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: OrganizationDetailsView
  },
  {
    path: '/organizations/:id/accounts',
    name: ORGANIZATION_ACCOUNTS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: OrganizationAccountsView
  },
  {
    path: '/organizations/:id/donations',
    name: ORGANIZATION_DONATIONS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: OrganizationDonationsView
  },
  {
    path: '/organizations/:id/minyanim',
    name: ORGANIZATION_MINYANIM_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: OrganizationMinyanimView
  },
  {
    path: '/organizations/:id/announcements',
    name: ORGANIZATION_ANNOUNCEMENTS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: OrganizationAnnouncementsView
  },
  {
    path: '/organizations/:id/shiurim',
    name: ORGANIZATION_SHIURIMS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: OrganizationShiurimView
  },
  {
    path: '/organizations/:id/screens',
    name: ORGANIZATION_SCREENS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: OrganizationScreensView
  },
  {
    path: '/organizations/:id/members',
    name: ORGANIZATION_MEMBERS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: OrganizationMembersView
  },
  {
    path: '/organizations/:id/synagogues',
    name: ORGANIZATION_SYNAGOGUES_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: OrganizationSynagoguesView
  },
  {
    path: '/organizations/:id/templates',
    name: ORGANIZATION_TEMPLATES_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: OrganizationTemplatesView
  },
  {
    path: '/organizations/:id/aliases',
    name: ORGANIZATION_ALIASES_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: OrganizationAliasView
  },
  {
    path: '/organizations/:id/invoices',
    name: ORGANIZATION_INVOICES_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: OrganizationInvoicesView
  },
  {
    path: '/organizations/:id/notifications',
    name: ORGANIZATION_NOTIFICATIONS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: OrganizationNotificationsView
  },
  {
    path: '/organizations/:id/settings',
    name: ORGANIZATION_SETTINGS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: OrganizationSettingsView
  },
  {
    path: '/organizations/:id/reports',
    name: ORGANIZATION_REPORTS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: OrganizationReportsView
  },
  {
    path: '/members',
    name: MEMBERS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: MembersView
  },
  {
    path: '/member/:membId/:orgId/:name',
    name: MEMBER_DETAILS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: MemberDetailsView
  },
  {
    path: '/member/:membId/:orgId/:name/donations',
    name: MEMBER_DONATIONS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: MemberDonationsView
  },
  {
    path: '/member/:membId/:orgId/:name/purpose',
    name: MEMBER_PURPOSES_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: MemberPurposesView
  },
  {
    path: '/member/:membId/:orgId/:name/seats',
    name: MEMBER_SEATS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: MemberSeatsView
  },
  {
    path: '/member/:membId/:orgId/:name/invoices',
    name: MEMBER_INVOICES_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: MemberInvoicesView
  },
  {
    path: '/member/:membId/:orgId/:name/invoices/payments',
    name: MEMBER_INVOICE_PAYMENTS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: MemberInvoicePaymentsView
  },
  {
    path: '/member/:membId/:orgId/:name/receipts',
    name: MEMBER_RECEIPTS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: MemberReceiptsView
  },
  {
    path: '/member/:membId/:orgId/:name/pledges',
    name: MEMBER_PLEDGES_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: MemberPledgesView
  },
  {
    path: '/member/:membId/:orgId/:name/pledges-items/payments',
    name: MEMBER_PLEDGE_ITEM_PAYMENT_LOGS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: MemberPledgePaymentsView 
  },
  {
    path: '/report/donation/stats',
    name: DONATION_REPORT_STATS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: DonationReportStatsView
  },
  {
    path: '/report/donation/report',
    name: DONATION_REPORT_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: DonationReportView
  },
  {
    path: '/report/seats',
    name: REPORT_SEATS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: ReportSeatsView
  },
  {
    path: '/report/pledges',
    name: REPORT_PLEDGES_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: ReportPledgesView
  },
  {
    path: '/accounts',
    name: ACCOUNT_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: AccountsView
  },
  {
    path: '/alerts',
    name: ALERT_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: AlertsView
  },
  {
    path: '/tickets',
    name: TICKETS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: TicketsView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (store.getters.sessionToken != null && store.getters.account.data != null) {
    store.dispatch(GET_MODULE_SETTINGS)
  }

  store.dispatch(UPDATE_ROUTER_LOADER, true)
  store.dispatch(CLEAR_ERRORMSG)

  setTimeout(() => {
    next();
  }, 1);
})

router.afterEach((to, from, next) => {
  store.dispatch(UPDATE_ROUTER_LOADER, false)

})

export default router