<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeRegisterDonationModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Register Donation</span>
            <br>
            <div class="modal__content">
                <addMemberModal v-if="showAddMemberModal" :organizationId="organizationId"
                    @close-add-member-modal="showAddMemberModal = false" @refresh-members="getMembers()" />
                <JewishDatePicker v-if="currentlyShowingJewDatePicker == DATE_PICKER_DONATION_DATE"
                    @select-date="selectDonationDate" @close-date-picker-modal="closeModal()" />
                <JewishDatePicker v-if="currentlyShowingJewDatePicker == DATE_PICKER_DONATION_DISPLAY_START_DATE"
                    @select-date="selectDonationDisplayStartDate" @close-date-picker-modal="closeModal()" />
                <JewishDatePicker v-if="currentlyShowingJewDatePicker == DATE_PICKER_DONATION_DISPLAY_END_DATE"
                    @select-date="selectDonationDisplayEndDate" @close-date-picker-modal="closeModal()" />
                <div class="row">
                    <div class="col-12">
                        <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage }}</p>
                        <Form @submit="registerDonation">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="title">General Info</h5>
                                    <div class="row">
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="template">Template</label>
                                                <select v-model="newDonationObj.title" class="form-control"
                                                    rules="required">
                                                    <option>---</option>
                                                    <template v-bind:key="index" v-for="(template, index) in templates">
                                                        <option :value="template">{{ template.name }}</option>
                                                    </template>
                                                </select>
                                                <ErrorMessage name="template" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="screen">Screen</label>
                                                <select v-model="newDonationObj.scr" class="form-control"
                                                    rules="required">
                                                    <option>---</option>
                                                    <template v-bind:key="index"
                                                        v-for="(screen, index) in organizationScreens">
                                                        <option :value="screen">{{ screen.name }}</option>
                                                    </template>
                                                </select>
                                                <ErrorMessage name="screen" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div v-if="memberId == null" class="col-4">
                                            <div class="form-group">
                                                <label for="member">Member <span v-on:click="showAddMemberModal = true"
                                                        class="badge add-new-badge text-primary">Add
                                                        New</span></label>
                                                <select v-model="newDonationObj.memb" class="form-control"
                                                    rules="required">
                                                    <option>---</option>
                                                    <template v-bind:key="index" v-for="(member, index) in members">
                                                        <option :value="member">{{ member.fname }} {{ member.lname }}
                                                        </option>
                                                    </template>
                                                </select>
                                                <ErrorMessage name="member" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="type">Type</label>
                                                <select v-model="newDonationObj.type" name="type" class="form-control"
                                                    rules="required">
                                                    <option>---</option>
                                                    <template v-bind:key="index"
                                                        v-for="(type, index) in DONTATION_TYPE">
                                                        <option>{{ type }}</option>
                                                    </template>
                                                </select>
                                                <ErrorMessage name="type" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="purpose">Purpose</label>
                                                <Field v-model="newDonationObj.purpose" rules="required" type="text"
                                                    name="purpose" class="form-control" id="purpose"
                                                    placeholder="Purpose" />
                                                <ErrorMessage name="purpose" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="amount">Amount</label>
                                                <Field v-model="newDonationObj.amount" rules="required" type="number"
                                                    min="0.1" step="0.01" name="amount" class="form-control" id="amount"
                                                    placeholder="0.00" />
                                                <ErrorMessage name="amount" class="form-text text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="title">Select Dates</h5>
                                    <div class="row">
                                        <div class="col-4">
                                            <div @click="currentlyShowingJewDatePicker = DATE_PICKER_DONATION_DATE"
                                                class="form-group">
                                                <label for="donationDate">Donation Date</label>
                                                <div class="row date-picker-row">
                                                    <div class="col-10">
                                                        <Field v-model="donationDate" rules="required" disabled
                                                            type="text" name="donationDate" class="form-control"
                                                            id="donationDate" placeholder="Donation Date" />
                                                    </div>
                                                    <div class="col-2">
                                                        <i class="pi pi-calendar-plus text-primary"></i>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="donationDate" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div @click="currentlyShowingJewDatePicker = DATE_PICKER_DONATION_DISPLAY_START_DATE"
                                                class="form-group">
                                                <label for="donationDisplayStartDate">Display Start Date</label>
                                                <div class="row date-picker-row">
                                                    <div class="col-10">
                                                        <Field v-model="donationDisplayStartDate" rules="required"
                                                            disabled type="text" name="donationDisplayStartDate"
                                                            class="form-control" id="donationDisplayStartDate"
                                                            placeholder="Donation Display Start Date" />
                                                    </div>
                                                    <div class="col-2">
                                                        <i class="pi pi-calendar-plus text-primary"></i>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="donationDisplayStartDate"
                                                    class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div @click="currentlyShowingJewDatePicker = DATE_PICKER_DONATION_DISPLAY_END_DATE"
                                                class="form-group">
                                                <label for="donationDisplayEndDate">Display End Date</label>
                                                <div class="row date-picker-row">
                                                    <div class="col-10">
                                                        <Field v-model="donationDisplayEndDate" rules="required"
                                                            disabled type="text" name="donationDisplayEndDate"
                                                            class="form-control" id="donationDisplayEndDate"
                                                            placeholder="Donation Display End Date" />
                                                    </div>
                                                    <div class="col-2">
                                                        <i class="pi pi-calendar-plus text-primary"></i>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="donationDisplayEndDate"
                                                    class="form-text text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div v-if="moduleSettings">
                              <div v-if="moduleSettings.includes(MODULE_SETTING_PLEDGES)" class="card">
                                <div class="card-body">
                                    <h5 class="title">Pledge</h5>
                                    <div class="row">
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="template">Type</label>
                                                <select v-model="newDonationObj.pldgType" class="form-control"
                                                    rules="required">
                                                    <option>---</option>
                                                    <template v-bind:key="index" v-for="(type, index) in pledgeTypes">
                                                        <option :value="type">{{ type.name }}</option>
                                                    </template>
                                                </select>
                                                <ErrorMessage name="template" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-1">
                                            <div class="form-check custom-checkbox">
                                                <label class="form-check-label" for="paid">
                                                    <Field v-model="newDonationObj.isPaid" :value="true"
                                                        :unchecked-value="false" type="checkbox"
                                                        class="form-check-input" id="paid" name="paid" />
                                                    <span class="checkmark"></span>
                                                    Paid
                                                </label>
                                                <ErrorMessage name="useCredit" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div v-if="newDonationObj.isPaid" class="col-3">
                                            <div class="form-group">
                                                <label for="paymentMethod">Payment Method</label>
                                                <select v-model="newDonationObj.paymentMethod" class="form-control"
                                                    rules="required">
                                                    <option>---</option>
                                                    <template v-bind:key="index"
                                                        v-for="(payment, index) in PAYMENT_METHODS">
                                                        <option :value="payment">{{ payment }}</option>
                                                    </template>
                                                </select>
                                                <ErrorMessage name="paymentMethod" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div v-if="newDonationObj.isPaid && newDonationObj.paymentMethod == PAYMENT_METHODS[3]" class="col-3">
                                            <div class="form-group">
                                                <label for="checkNumber">Check Number</label>
                                                <Field v-model="newDonationObj.checkNumber" rules="required" type="text"
                                                    name="checkNumber" class="form-control" id="checkNumber"
                                                    placeholder="##########" />
                                                <ErrorMessage name="checkNumber" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div v-if="newDonationObj.isPaid && newDonationObj.paymentMethod == PAYMENT_METHODS[4]" class="col-3">
                                            <div class="form-group">
                                                <label for="cardOptions">Charity Card Option</label>
                                                <select v-model="newDonationObj.charityCardOption" class="form-control"
                                                    rules="required">
                                                    <option>---</option>
                                                    <template v-bind:key="index"
                                                        v-for="(cardOptions, index) in CHARITY_CARD_OPTIONS">
                                                        <option :value="cardOptions">{{ cardOptions }}</option>
                                                    </template>
                                                </select>
                                                <ErrorMessage name="cardOptions" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div v-if="newDonationObj.isPaid && newDonationObj.paymentMethod == PAYMENT_METHODS[4] && newDonationObj.charityCardOption == CHARITY_CARD_OPTIONS[4]" class="col-4">
                                            <div class="form-group">
                                                <label for="charityCardOptionOther">Charity Card Option Other</label>
                                                <Field v-model="charityCardOptionOther" rules="required" type="text"
                                                    name="charityCardOptionOther" class="form-control"
                                                    id="charityCardOptionOther" placeholder="Type here" />
                                                <ErrorMessage name="charityCardOptionOther"
                                                    class="form-text text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div class="row justify-content-end modal__actions">
                                <button v-on:click="closeDonationModal()" class="col-1 btn btn-close">
                                    <img src="../../../assets/icons/close-icon.svg" alt=""> Cancel
                                </button>
                                <button type="submit" class="col-1 btn btn-save"
                                    :disabled="donationIsLoading ? true : false">
                                    <span v-if="donationIsLoading" class="spinner-border spinner-border-sm"
                                        role="status" aria-hidden="true"></span>
                                    <span v-if="!donationIsLoading"><img src="../../../assets/icons/save-icon.svg"
                                            alt=""> Save</span>
                                </button>
                            </div>
                        </Form>
                    </div>
                    <!-- <div class="col-6">
                        <br>
                        <div class="card">
                            <div class="card-body">
                                <span v-html="renderContent(newDonationObj.title, newDonationObj)"></span>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import JewishDatePicker from '@/components/JewishDatePicker.vue';
import addMemberModal from '@/components/modals/Member/Add_Member_Modal.vue'

import { GET_MEMBERS, GET_SCREENS, GET_TEMPLATES, REGISTER_DONATION, GET_ORGANIZATION_SCREENS, GET_PLEDGE_TYPES } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';
import { DONTATION_TYPE, DONATION_TITLES, DONATION_TYPE_YEARLY, DONATION_TYPE_MONTHLY, DONATION_TYPE_WEEKLY, DONATION_TYPE_DAILY, DATE_PICKER_DONATION_DATE, DATE_PICKER_DONATION_DISPLAY_START_DATE, DATE_PICKER_DONATION_DISPLAY_END_DATE, USER_ROLE_ADMIN } from '@/utils/const';
import { PAYMENT_METHODS, CHARITY_CARD_OPTIONS, MODULE_SETTING_PLEDGES } from '@/utils/const';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        organizationId: String,
        memberId: String
    },
    components: {
        addMemberModal,
        JewishDatePicker,
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            DONTATION_TYPE,
            DONATION_TITLES,
            DONATION_TYPE_YEARLY,
            DONATION_TYPE_MONTHLY,
            DONATION_TYPE_WEEKLY,
            DONATION_TYPE_DAILY,
            DATE_PICKER_DONATION_DATE,
            DATE_PICKER_DONATION_DISPLAY_START_DATE,
            DATE_PICKER_DONATION_DISPLAY_END_DATE,
            PAYMENT_METHODS,
            CHARITY_CARD_OPTIONS,
            MODULE_SETTING_PLEDGES,
            currentlyShowingJewDatePicker: null,
            showModal: true,
            showAddMemberModal: false,
            newDonationObj: {
                title: null,
                scr: null,
                amount: null,
                purpose: null,
                pldgType: null,
                isPaid: true,
                paymentMethod: null,
                checkNumber: null,
                charityCardOption: null,
                charityCardOptionOther: null,
                memb: null,
                org: null,
                donationDate: null,
                donationDisplayStartDate: null,
                donationDisplayEndDate: null
            },
            donationDate: null,
            donationDisplayStartDate: null,
            donationDisplayEndDate: null
        }
    },
    watch: {
        'newDonationObj.type': function (newValue, oldValue) {
            this.updateDonationDisplayEndDate();
        },
        'newDonationObj.donationDisplayStartDate': function (newValue, oldValue) {
            this.updateDonationDisplayEndDate();
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        screens() {
            return this.$store.getters.screens.data
        },
        screensIsLoading() {
            return this.$store.getters.screens.isLoading
        },
        organizationScreens() {
            return this.$store.getters.organizationScreens.data
        },
        organizationScreensIsLoading() {
            return this.$store.getters.organizationScreens.isLoading
        },
        templates() {
            return this.$store.getters.templates.data
        },
        templatesIsLoading() {
            return this.$store.getters.templates.isLoading
        },
        donation() {
            return this.$store.getters.donation.data
        },
        donationIsLoading() {
            return this.$store.getters.donation.isLoading
        },
        members() {
            return this.$store.getters.members.data
        },
        membersIsLoading() {
            return this.$store.getters.members.isLoading
        },
        pledgeTypes() {
            return this.$store.getters.pledgeTypes.data
        },
        pledgeTypesIsLoading() {
            return this.$store.getters.pledgeTypes.isLoading
        },
        moduleSettings() {
            if (this.$store.getters.moduleSettings.data != null) {
                return this.$store.getters.moduleSettings.data.map((e) => e.setting)
            }
        },
        account() {
            return this.$store.getters.account.data
        },
    },
    methods: {
        selectDonationDate(selectedDate) {
            this.newDonationObj.donationDate = this.$filters.convertHDateToGregorian(selectedDate)
            this.donationDate = this.$filters.convertToHebrewDate(this.newDonationObj.donationDate)
            this.closeModal()
        },
        selectDonationDisplayStartDate(selectedDate) {
            this.newDonationObj.donationDisplayStartDate = this.$filters.convertHDateToGregorian(selectedDate)
            this.donationDisplayStartDate = this.$filters.convertToHebrewDate(this.newDonationObj.donationDisplayStartDate)
            this.closeModal()
        },
        selectDonationDisplayEndDate(selectedDate) {
            this.newDonationObj.donationDisplayEndDate = this.$filters.convertHDateToGregorian(selectedDate)
            this.donationDisplayEndDate = this.$filters.convertToHebrewDate(this.newDonationObj.donationDisplayEndDate)
            this.closeModal()
        },
        updateDonationDisplayEndDate() {
            if (this.newDonationObj.donationDisplayStartDate) {
                let startDate = new Date(this.newDonationObj.donationDisplayStartDate);
                switch (this.newDonationObj.type) {
                    case DONATION_TYPE_DAILY:
                        startDate.setDate(startDate.getDate() + 1);
                        break;
                    case DONATION_TYPE_WEEKLY:
                        startDate.setDate(startDate.getDate() + 7);
                        break;
                    case DONATION_TYPE_MONTHLY:
                        startDate.setMonth(startDate.getMonth() + 1);
                        break;
                    case DONATION_TYPE_YEARLY:
                        startDate.setFullYear(startDate.getFullYear() + 1);
                        break;
                    default:
                        return;
                }
                this.donationDisplayEndDate = this.$filters.convertToHebrewDate(startDate.toISOString().split('T')[0])
                this.newDonationObj.donationDisplayEndDate = startDate.toISOString().split('T')[0]; // Converts date object to YYYY-MM-DD format
            }
        },
        renderContent(template, donation) {
            let content = '';
            let replacements = []

            if (template) {
                content = template.content
                Object.keys(donation).forEach(key => {
                    const regex = new RegExp(`{${key}}`, 'g');
                    if (content) {
                        if (content.includes(`{${key}}`)) {
                            replacements.push({
                                replace: regex,
                                replaceBy: key == 'amount' ? this.$filters.formatPrice(donation[key], 'USD') : key == 'member' ? `${donation['member'].fname} ${donation['member'].lname}` : donation[key]
                            })
                        }
                    }

                });

                replacements.forEach((e) => {
                    content = content.replace(e.replace, e.replaceBy)
                })
            }
            return content;
        },
        setValues() {
            this.newDonationObj.org = this.organizationId
            this.newDonationObj.memb = this.memberId
        },
        closeModal() {
            this.currentlyShowingJewDatePicker = null;
        },
        closeDonationModal() {
            this.$emit('closeRegisterDonationModal')
        },
        getTemplates() {
            this.$store.dispatch(GET_TEMPLATES)
        },
        getScreens() {
            this.$store.dispatch(GET_ORGANIZATION_SCREENS, { org: this.$route.params.id ?? this.organizationId })
        },
        getOrgnizationScreens() {
            this.$store.dispatch(GET_ORGANIZATION_SCREENS, { org: this.$route.params.id ?? this.organizationId })
        },
        getPledgeTypes() {
            this.$store.dispatch(GET_PLEDGE_TYPES, { org: this.organizationId ?? this.account.org })
        },
        getMembers() {
            if (this.account.role == USER_ROLE_ADMIN) {
                this.$store.dispatch(GET_MEMBERS, { org: this.organizationId })
            } else {
                this.$store.dispatch(GET_MEMBERS, { org: this.account.org })
            }
        },
      registerDonation() {
          if (this.moduleSettings.includes(MODULE_SETTING_PLEDGES) && !this.newDonationObj.pldgType) {
              this.$toast.error('Please select a pledge type.');   
              return     
          }
          this.newDonationObj.title = this.newDonationObj.title.id
          this.newDonationObj.scr = this.newDonationObj.scr.screen.id

          if (this.newDonationObj.pldgType) {
            this.newDonationObj.pldgType = this.newDonationObj.pldgType.id
          }

          if (this.memberId == null) {
              this.newDonationObj.memb = this.newDonationObj.memb.id
          }
          
          this.$store.dispatch(REGISTER_DONATION, { ...this.newDonationObj }).then((result) => {
              if (result) {
                  this.$emit('refreshDonations')
                  this.$emit('closeRegisterDonationModal')
              }
          })
      },
    },
    created() {
        this.setValues()
        this.getTemplates()
        this.getScreens()
        this.getMembers()
        this.getPledgeTypes()
        this.getOrgnizationScreens()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    position: relative;
    left: 10%;
    margin-top: 20px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
    max-height: 96vh;
    overflow-x: auto;
    overflow-y: auto;
}

::v-deep .modal__content {
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.modal__content .nav-tabs .nav-link {
    font-size: 15px !important;
}

.form-check {
    position: relative;
    top: 35px;
}

</style>