<template>
    <div class="dashboard-body">
        <div class="row">
            <sidenav class="custom-col-width-2" />
            <div style="padding-left: 0px!important" class="custom-col-width-10 content-section dashboard-content">
                <navbar />
                <viewDonationTemplateModal v-if="showViewDonationModal" :selectedDonationToView="selectedDonationToView"
                    @close-view-donation-modal="showViewDonationModal = false" />
                <registerDonationModal v-if="showRegisterDonationModal" :organizationId="organizationId"
                    @close-register-donation-modal="showRegisterDonationModal = false"
                    @refresh-donations="getDonations()" />
                <editDonationModal v-if="showEditDonationModal" :selectedDonation="selectedDonation"
                    @close-edit-donation-modal="showEditDonationModal = false" @refresh-donations="getDonations()" />
                <deleteDonationModal v-if="showDeleteDonationModal" :selectedDonationId="selectedDonationId"
                    @close-delete-donation-modal="showDeleteDonationModal = false"
                    @refresh-donations="getDonations()" />
                <div class="content">
                    <br>
                    <div class="row button-grp justify-content-between">
                        <div class="col">
                            <h4>Organization</h4>
                        </div>
                        <div class="col">
                            <button v-on:click="showRegisterDonationModal = true" class="btn btn-add"> <i
                                    class="pi pi-plus"></i> Register Donation</button>
                        </div>
                    </div>
                    <div class="row justify-content-between">
                        <organizationNavbar class="" />
                    </div>
                    <div class="row filter-grp">
                        <div class="col-lg-9 col-md-7">
                            <i class="pi pi-search" aria-hidden="true"></i>
                            <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
                        </div>
                        <div class="col-lg-3 col-md-5">
                            <div class="row">
                                <div class="btn col"><img src="../../assets/icons/calendar-icon.svg" alt=""> Select Date
                                </div>
                                <div class="btn col"><img src="../../assets/icons/filter-icon.svg" alt="">
                                    Filters</div>
                            </div>
                        </div>
                    </div>
                    <div class="row view-controls">
                        <div class="col-lg-4 col-md-6">
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <button v-on:click="switchDonationView(DONATION_VIEW_LIST)" type="button"
                                    :class="[selectedView == DONATION_VIEW_LIST ? 'btn-selected' : null, 'btn btn-list-view']">List
                                    View</button>
                                <button v-on:click="switchDonationView(DONATION_VIEW_CALENDAR)" type="button"
                                    :class="[selectedView == DONATION_VIEW_CALENDAR ? 'btn-selected' : null, 'btn btn-calendar-view']">Calendar
                                    View</button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12" v-if="selectedView == DONATION_VIEW_CALENDAR">
                            <calendarDonationsView />
                        </div>
                        <div class="col-lg-12 offset-lg-0" v-if="selectedView == DONATION_VIEW_LIST">
                            <div v-if="donationsIsLoading && donations == null">
                                <div class="row">
                                    <div class="col-12 shimmer-loader-row">
                                        <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                            :border-radius="'1px'" :foreground-color="'#DFDFFAFF'">
                                            <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                        </content-loader>
                                    </div>
                                    <br>
                                    <div class="col-12 shimmer-loader-row">
                                        <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                            :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                            <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                        </content-loader>
                                    </div>
                                    <br>
                                    <div class="col-12 shimmer-loader-row">
                                        <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                            :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                            <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                        </content-loader>
                                    </div>
                                    <br>
                                    <div class="col-12 shimmer-loader-row">
                                        <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                            :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                            <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                        </content-loader>
                                    </div>
                                    <br>
                                    <div class="col-12 shimmer-loader-row">
                                        <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                            :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                            <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                        </content-loader>
                                    </div>
                                    <br>
                                    <div class="col-12 shimmer-loader-row">
                                        <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                            :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                            <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                        </content-loader>
                                    </div>
                                    <br>
                                    <div class="col-12 shimmer-loader-row">
                                        <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                            :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                            <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                        </content-loader>
                                    </div>
                                    <br>
                                    <div class="col-12 shimmer-loader-row">
                                        <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                            :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                            <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                        </content-loader>
                                    </div>
                                    <br>
                                    <div class="col-12 shimmer-loader-row">
                                        <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                            :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                            <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                        </content-loader>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="card">
                                <div class="card-body">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <!-- <th scope="col">Title</th> -->
                                                <th scope="col">Donor</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Purpose</th>
                                                <th scope="col">Screen</th>
                                                <th scope="col">Template</th>
                                                <th scope="col">Donation Date</th>
                                                <th scope="col">Display Date</th>
                                                <th scope="col">Display</th>
                                                <th scope="col">Registered By</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-bind:key="index" v-for="(donation, index) in donations">
                                                <tr :class="[isOdd(index + 1) ? 'striped' : null]">
                                                    <th scope="row">{{ index + 1 }}</th>
                                                    <!-- <td><span v-html="renderContent(donation.template.content, donation)"></span></td> -->
                                                    <td>{{ donation.member.fname }} {{ donation.member.lname }}</td>
                                                    <td>{{ $filters.formatPrice(donation.amount, 'USD') }}</td>
                                                    <td>{{ donation.purpose }}</td>
                                                    <td>{{ donation.organizationScreen != null ?
                                                        donation.organizationScreen.name : donation.screen.name }}</td>
                                                    <td>{{ donation.template.name }}</td>
                                                    <td><span class="frankRuhl-font">{{
                                                            $filters.convertToHebrewDate(donation.donationDate)
                                                            }}</span></td>
                                                    <td><span class="frankRuhl-font">{{
                                                            $filters.convertToHebrewDate(donation.donationDisplayStartDate)
                                                            }}</span>
                                                        <small> | </small>
                                                        <span class="frankRuhl-font">{{
                                                            $filters.convertToHebrewDate(donation.donationDisplayEndDate)
                                                            }}</span>
                                                    </td>
                                                    <td>
                                                        <label v-on:click="toggleDonationIsActive(donation.id)"
                                                            class="form-switch">
                                                            <input
                                                                v-if="!(actingDonation != null && actingDonationIsLoading && actingDonation.id == donation.id)"
                                                                type="checkbox" :checked="donation.isActive">
                                                            <span v-else class="spinner-border spinner-border-sm"
                                                                role="status" aria-hidden="true"></span>
                                                            <i></i>
                                                        </label>
                                                    </td>
                                                    <td>{{ donation.user.fname }}</td>
                                                    <td>
                                                        <i v-on:click="selectDontationToView(donation)"
                                                            class="pi pi-eye text-secondary"></i>
                                                        <i v-on:click="selectDontationToEdit(donation)"
                                                            class="pi pi-pencil text-primary"></i>
                                                        <i v-on:click="selectDontationToDelete(donation.id)"
                                                            class="pi pi-trash text-danger"></i>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ContentLoader } from 'vue-content-loader';

import sidenav from '@/components/Sidenav.vue'
import navbar from '@/components/Navbar.vue'
import organizationNavbar from '@/components/Organization_Navbar.vue'
import viewDonationTemplateModal from '@/components/modals/Donation/View_Donation_Template_Modal.vue'
import registerDonationModal from '@/components/modals/Donation/Register_Donation_Modal.vue'
import editDonationModal from '@/components/modals/Donation/Edit_Donation_Modal.vue'
import deleteDonationModal from '@/components/modals/Donation/Delete_Donation_Modal.vue'
import calendarDonationsView from '@/components/Calendar_View.vue'
import { GET_DONATIONS, TOGGLE_DONATION_IS_ACTIVE } from '@/store/action_types'
import { DONATION_VIEW_LIST, DONATION_VIEW_CALENDAR, USER_ROLE_ADMIN } from '@/utils/const'

export default {
    title: 'Members',
    components: {
        sidenav,
        navbar,
        organizationNavbar,
        editDonationModal,
        deleteDonationModal,
        registerDonationModal,
        calendarDonationsView,
        viewDonationTemplateModal,
        ContentLoader
    },
    data() {
        return {
            USER_ROLE_ADMIN,
            DONATION_VIEW_LIST,
            DONATION_VIEW_CALENDAR,
            showRegisterDonationModal: false,
            showEditDonationModal: false,
            showDeleteDonationModal: false,
            showViewDonationModal: false,
            selectedView: DONATION_VIEW_LIST,
            selectedDonation: null,
            selectedDonationId: null,
            selectedDonationToView: null,
            selectedMember: null,
            selectedMemberId: null,
            organizationId: null
        }
    },
    computed: {
        donationsIsLoading() {
            return this.$store.getters.donations.isLoading
        },
        donations() {
            return this.$store.getters.donations.data
        },
        actingDonation() {
            return this.$store.getters.donation.data
        },
        actingDonationIsLoading() {
            return this.$store.getters.donation.isLoading
        },
        account() {
            return this.$store.getters.account.data
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        selectDontationToEdit(donation) {
            this.selectedDonation = donation
            this.showEditDonationModal = true
        },
        selectDontationToDelete(donationId) {
            this.selectedDonationId = donationId
            this.showDeleteDonationModal = true
        },
        selectDontationToView(donation) {
            this.selectedDonationToView = donation
            this.showViewDonationModal = true
        },
        renderContent(template, donation) {
            let replacements = []

            Object.keys(donation).forEach(key => {
                const regex = new RegExp(`{${key}}`, 'g');
                if (template.includes(`{${key}}`)) {
                    replacements.push({
                        replace: regex,
                        replaceBy: donation[key]
                    })
                }

            });

            replacements.forEach((e) => {
                template = template.replace(e.replace, e.replaceBy)
            })
            return template;
        },
        setValues() {
            this.organizationId = this.$route.params.id
        },
        toggleDonationIsActive(id) {
            this.$store.dispatch(TOGGLE_DONATION_IS_ACTIVE, { id })
        },
        switchDonationView(type) {
            this.selectedView = type
        },
        getDonations() {
            this.$store.dispatch(GET_DONATIONS, { org: this.$route.params.id })
        }
    },
    mounted() {
        this.getDonations()
        this.setValues()
    }
}
</script>
<style scoped>
.content .card,
.content .card-body {
    padding: 0px;
}

.content .card .card-content {
    padding: 15px;
}

.content .card .card-content .value {
    font-weight: 300 !important;
}

.content .card,
.content .card-body {
    padding: 0px;
}

.custom-col-width-2 {
    width: 16.66%;
}

.custom-col-width-10 {
    width: 83.3%;
}

@media (max-width: 1000px) {
    .custom-col-width-2 {
        width: 12%;
    }

    .custom-col-width-10 {
        width: 88%;
    }
}
</style>