import axios, { AxiosResponse } from 'axios'
import { SET_ALERT_NOTIFICATIONS, SET_ALERT_NOTIFICATION, SET_ERRORMSG } from '../mutation_types'
import {
    CLEAR_ALERT_NOTIFICATIONS_ISLOADING,
    VIEW_ALERT_NOTIFICATION,
    GET_ALERT_NOTIFICATIONS,
    GET_ALERT_NOTIFICATION,
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import AlertNotification from '@/services/alert_notification'
import { Module } from 'vuex'
import { GetAlertNotification, ViewAlertNotification } from '@/dtos/alert_notification.dto'

const alertNotification: Module<any, {}> = {
    state: {
        alertNotification: {
            data: null,
            isLoading: false
        },
        alertNotifications: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        alertNotification: (state: { alertNotification: any }) => state.alertNotification,
        alertNotifications: (state: { alertNotifications: any }) => state.alertNotifications
    },
    mutations: {
        [SET_ALERT_NOTIFICATION]: (state: { alertNotification: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.alertNotification.data = payload.data : null, state.alertNotification.isLoading = payload.isLoading },
        [SET_ALERT_NOTIFICATIONS]: (state: { alertNotifications: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.alertNotifications.data = payload.data : null, state.alertNotifications.isLoading = payload.isLoading }
    },
    actions: {
        [GET_ALERT_NOTIFICATION]: async({ commit, rootState }: any, payload: any) => {
            commit(SET_ALERT_NOTIFICATION, { data: null, isLoading: true })

            if(rootState.account.account.data && rootState.account.sessionToken) {
              let result: AxiosResponse = await AlertNotification.getAlertNotification(payload as GetAlertNotification)
          
              if(result) {
                  if (result.data.status) {
                      console.log('result.data.data', result.data.data)
                      commit(SET_ALERT_NOTIFICATION, { data: result.data.data, isLoading: false })
                      return result.data.data;
                  }
                  commit(SET_ALERT_NOTIFICATION, { data: null, isLoading: false })
                  commit(SET_ERRORMSG, result.data.msg)
                  return false;
              } else {
                  commit(SET_ALERT_NOTIFICATION, { isLoading: false })
                  return false;
              }
            } else {
              return false;
            } 
        },
        [VIEW_ALERT_NOTIFICATION]: async({ commit, rootState }: any, payload: any) => {
            commit(SET_ALERT_NOTIFICATION, { data: null, isLoading: true })

            if(rootState.account.account.data && rootState.account.sessionToken) {

              let result: AxiosResponse = await AlertNotification.viewAlertNotification(payload as ViewAlertNotification)
            
              if(result) {
                  if (result.data.status) {
                      console.log('result.data.data', result.data.data)
                      commit(SET_ALERT_NOTIFICATION, { data: result.data.data, isLoading: false })
                      return true;
                  }
                  commit(SET_ALERT_NOTIFICATION, { data: null, isLoading: false })
                  commit(SET_ERRORMSG, result.data.msg)
                  return false;
              } else {
                  commit(SET_ALERT_NOTIFICATION, { isLoading: false })
                  return false;
              }
            } else {
              return false;
            } 
        },
        [CLEAR_ALERT_NOTIFICATIONS_ISLOADING]: ({ commit }) => {
            commit(SET_ALERT_NOTIFICATION, { isLoading: false })
            commit(SET_ALERT_NOTIFICATIONS, { isLoading: false })
        }
    }
}

export default alertNotification