<template>
    <div class="card organization-nav">
        <div class="card-body">
            <div class="row">
                <div class="">
                    <router-link :class="[ route == ORGANIZATION_DETAILS_ROUTE ? 'btn-add' : null, 'btn btn-link']"
                        :to="{ name: ORGANIZATION_DETAILS_ROUTE }">Details</router-link>
                </div>
                <div class="">
                    <router-link :class="[ route == ORGANIZATION_ACCOUNTS_ROUTE ? 'btn-add' : null, 'btn btn-link']"
                        :to="{ name: ORGANIZATION_ACCOUNTS_ROUTE }">Accounts</router-link>
                </div>
                <div class="">
                    <router-link :class="[ route == ORGANIZATION_DONATIONS_ROUTE ? 'btn-add' : null, 'btn btn-link']"
                        :to="{ name: ORGANIZATION_DONATIONS_ROUTE }">Donations</router-link>
                </div>
                <div v-if="moduleSettings">
                    <div v-if="moduleSettings.includes(MODULE_SETTING_MINYANIM)" class="">
                        <router-link :class="[route == ORGANIZATION_MINYANIM_ROUTE ? 'btn-add' : null, 'btn btn-link']"
                            :to="{ name: ORGANIZATION_MINYANIM_ROUTE }">Minyanim</router-link>
                    </div>
                </div>
                <div class="">
                    <router-link :class="[route == ORGANIZATION_ANNOUNCEMENTS_ROUTE ? 'btn-add' : null, 'btn btn-link']"
                        :to="{ name: ORGANIZATION_ANNOUNCEMENTS_ROUTE }">Announcements</router-link>
                </div>
                <div class="">
                    <router-link :class="[route == ORGANIZATION_SHIURIMS_ROUTE ? 'btn-add' : null, 'btn btn-link']"
                        :to="{ name: ORGANIZATION_SHIURIMS_ROUTE }">Shiurim</router-link>
                </div>
                <div v-if="moduleSettings">
                    <div v-if="moduleSettings.includes(MODULE_SETTING_SCREENS)" class="">
                        <router-link :class="[route == ORGANIZATION_SCREENS_ROUTE ? 'btn-add' : null, 'btn btn-link']"
                            :to="{ name: ORGANIZATION_SCREENS_ROUTE }">Screens</router-link>
                    </div>
                </div>
                <div v-if="moduleSettings">
                    <div v-if="moduleSettings.includes(MODULE_SETTING_MEMBER)" class="">
                        <router-link :class="[route == ORGANIZATION_MEMBERS_ROUTE ? 'btn-add' : null, 'btn btn-link']"
                            :to="{ name: ORGANIZATION_MEMBERS_ROUTE }">Members</router-link>
                    </div>
                </div>
                <div v-if="moduleSettings">
                    <div v-if="moduleSettings.includes(MODULE_SETTING_SEATS)" class="">
                        <router-link
                            :class="[route == ORGANIZATION_SYNAGOGUES_ROUTE ? 'btn-add' : null, 'btn btn-link']"
                            :to="{ name: ORGANIZATION_SYNAGOGUES_ROUTE }">Seats</router-link>
                    </div>
                </div>
                <div class="">
                    <router-link :class="[route == ORGANIZATION_TEMPLATES_ROUTE ? 'btn-add' : null, 'btn btn-link']"
                        :to="{ name: ORGANIZATION_TEMPLATES_ROUTE }">Templates</router-link>
                </div>
                <div v-if="moduleSettings">
                    <div v-if="moduleSettings.includes(MODULE_SETTING_PLEDGES)" class="">
                        <router-link :class="[route == ORGANIZATION_ALIASES_ROUTE ? 'btn-add' : null, 'btn btn-link']"
                          :to="{ name: ORGANIZATION_ALIASES_ROUTE }">Alias</router-link>
                    </div>
                </div>
                <!-- <div class="">
                    <router-link :class="[route == ORGANIZATION_INVOICES_ROUTE ? 'btn-add' : null, 'btn btn-link']"
                        :to="{ name: ORGANIZATION_INVOICES_ROUTE }">Statements</router-link>
                </div> -->
                <div class="">
                    <router-link :class="[route == ORGANIZATION_NOTIFICATIONS_ROUTE ? 'btn-add' : null, 'btn btn-link']"
                        :to="{ name: ORGANIZATION_NOTIFICATIONS_ROUTE }">Notifications</router-link>
                </div>
                <div v-if="account.role == USER_ROLE_ADMIN" class="">
                    <router-link :class="[route == ORGANIZATION_SETTINGS_ROUTE ? 'btn-add' : null, 'btn btn-link']"
                        :to="{ name: ORGANIZATION_SETTINGS_ROUTE }">Settings</router-link>
                </div>
                <!-- <div v-if="account.role == USER_ROLE_ADMIN" class="">
                    <router-link :class="[route == ORGANIZATION_REPORTS_ROUTE ? 'btn-add' : null, 'btn btn-link']"
                        :to="{ name: ORGANIZATION_REPORTS_ROUTE }">Reports</router-link>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import { ORGANIZATION_DETAILS_ROUTE, ORGANIZATION_ACCOUNTS_ROUTE, ORGANIZATION_DONATIONS_ROUTE, ORGANIZATION_ANNOUNCEMENTS_ROUTE, ORGANIZATION_SHIURIMS_ROUTE, ORGANIZATION_MINYANIM_ROUTE, ORGANIZATION_SCREENS_ROUTE, ORGANIZATION_MEMBERS_ROUTE, ORGANIZATION_SYNAGOGUES_ROUTE, ORGANIZATION_TEMPLATES_ROUTE, ORGANIZATION_ALIASES_ROUTE, ORGANIZATION_INVOICES_ROUTE, ORGANIZATION_NOTIFICATIONS_ROUTE, ORGANIZATION_SETTINGS_ROUTE, ORGANIZATION_REPORTS_ROUTE } from '@/router/routes'
import { USER_ROLE_ADMIN, MODULE_SETTING_SCREENS, MODULE_SETTING_MEMBER, MODULE_SETTING_SEATS, MODULE_SETTING_MINYANIM, MODULE_SETTING_PLEDGES } from '@/utils/const';

export default {
    data() {
        return {
            ORGANIZATION_DETAILS_ROUTE,
            ORGANIZATION_ACCOUNTS_ROUTE,
            ORGANIZATION_DONATIONS_ROUTE,
            ORGANIZATION_MINYANIM_ROUTE,
            ORGANIZATION_ANNOUNCEMENTS_ROUTE,
            ORGANIZATION_SHIURIMS_ROUTE,
            ORGANIZATION_SCREENS_ROUTE,
            ORGANIZATION_MEMBERS_ROUTE,
            ORGANIZATION_SYNAGOGUES_ROUTE,
            ORGANIZATION_TEMPLATES_ROUTE,
            ORGANIZATION_ALIASES_ROUTE,
            ORGANIZATION_INVOICES_ROUTE,
            ORGANIZATION_NOTIFICATIONS_ROUTE,
            ORGANIZATION_SETTINGS_ROUTE,
            ORGANIZATION_REPORTS_ROUTE,
            USER_ROLE_ADMIN,
            MODULE_SETTING_SCREENS,
            MODULE_SETTING_MEMBER,
            MODULE_SETTING_MINYANIM,
            MODULE_SETTING_SEATS,
            MODULE_SETTING_PLEDGES
        }
    },
    computed: {
        route() {
            return this.$route.name
        },
        moduleSettings() {
            if (this.$store.getters.moduleSettings.data != null) {
                return this.$store.getters.moduleSettings.data.map((e) => e.setting)
            }
        },
        account() {
            return this.$store.getters.account.data
        }
    }
}
</script>
<style scoped>
.content .organization-nav {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 15px;
    border: 1px solid #E0E2E7
}

.content .organization-nav .row {
    margin-left: 0px;
    margin-right: -8px;
}

.content .organization-nav .row>div{
    margin-right: 10px;
}

.content .organization-nav .btn {
    font-size: 16px;
    color: #667085!important;
    text-decoration: unset;
    border-radius: 7px;
}

.dashboard-body .btn-add {
    font-weight: 600;
    color: #14243D!important;
    background-color: #F4F4F8!important;
    border-color: #F4F4F8!important;
    cursor: pointer;
}

.content .organization-nav .btn:hover {
    background-color: #F4F4F8;
}

.content .organization-nav .card-body {
    padding: 4px!important;
    border-radius: 8px;
}
</style>